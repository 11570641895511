import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import api from "../api";
import { ProgressSpinner } from "primereact/progressspinner";

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [tab1Data, setTab1Data] = useState([]);
  const [tab2Data, setTab2Data] = useState([]);
  const [tab3Data, setTab3Data] = useState([]);
  const [tab4Data, setTab4Data] = useState([]);
  const [tab5Data, setTab5Data] = useState([]);
  const [tab6Data, setTab6Data] = useState([]);
  const [tab7Data, setTab7Data] = useState([]);

  const userID = sessionStorage.getItem("userid");
  const toast = useRef(null);
  const presentDate = new Date();
  const formatPresentDate = `${presentDate
    .getDate()
    .toString()
    .padStart(2, "0")}-${(presentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${presentDate.getFullYear()} ${presentDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${presentDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${presentDate.getSeconds().toString().padStart(2, "0")}`;
  const downloadDate = `${presentDate.getDate().toString().padStart(2, "0")}-${(
    presentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${presentDate.getFullYear()}_${presentDate
    .getHours()
    .toString()
    .padStart(2, "0")}-${presentDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}-${presentDate
    .getSeconds()
    .toString()
    .padStart(2, "0")}-${presentDate
    .getMilliseconds()
    .toString()
    .padStart(3, "0")}`;


    const replaceValue = (value) => {
      if (value === undefined || value === null) {
        return '';
      }
      if (value === '-999') {
        return '';
      }
      return value.replace(/\$/g, ', ');
    };




    const excelHeaders = {
      tab1Data: [
          { key: "slNo", text: "Sl No" },
          { key: "fld_is_loggedin_user_id", text: "User ID" },
          { key: "fld_hh_id", text: "HH ID" },
          // { key: "fld_member_id", text: "Member ID" },
          { key: "fld_asha_name", text: "Name of the Interviewer" },
          { key: "fld_date_of_visit", text: "Date of visit" },
          { key: "fld_state_name", text: "State Name" },
          { key: "fld_district_name", text: "District Name" },
          { key: "fld_taluk_name", text: "Taluk Name" },
          { key: "fld_village_name", text: "Village Name" },
          { key: "fld_asha_id", text: "Asha ID" },
          // { key: "fld_informant_name", text: "Informant Name" },
          { key: "fld_family_head_name", text: "Family Head Name" },
          { key: "fld_phone_no_1", text: "Phone Number 1" },
          { key: "fld_phone_no_2", text: "Phone Number 2" },
          { key: "fld_address", text: "Address" },
          { key: "fld_residing_year_since", text: "Years Residing" },
          // { key: "fld_previous_residence", text: "Previous Residence" },
          // { key: "fld_native_place", text: "Native Place" },
          // { key: "fld_reason_for_migration_name", text: "Reason for Migration" },
          // { key: "fld_other_reason", text: "Other Reason" },
          { key: "fld_landmark", text: "Landmark" },
          { key: "fld_type_of_family_name", text: "Type of Family" },
          { key: "fld_other_social_sevice_card", text: "Other social service card " },
          { key: "fld_card_holder_status_name", text: "Card Holder Status" },
          { key: "fld_other_social_sevice_card", text: "Other social service card" },
          { key: "fld_total_family_member", text: "Total Family Members" },
          { key: "fld_total_family_income", text: "Total Family Income" },
          { key: "fld_any_property_own_Name", text: "Property Ownership" },
          { key: "fld_property_details_name", text: "Property Details" },
          { key: "fld_socio_status_name", text: "Socioeconomic Status" },
          { key: "fld_religion_name", text: "Religion" },
          { key: "fld_other_religion", text: "Other Religion" },
          { key: "fld_caste_name", text: "Caste" },
          { key: "fld_rec_vtl_event_family_name", text: "Vital Event in Family" },
          { key: "fld_others_event", text: "Other events" },
          { key: "fld_ownership_of_house_name", text: "Ownership of House" },
          { key: "fld_others_ownership", text: "Other Ownership" },
          { key: "fld_family_cons_environmental_name", text: "Environmental Concern" },
          { key: "fld_detail_cons_environmental_name", text: "Environmental Concern Details" },
          { key: "fld_open_drains_name", text: "Open Drains" },
          { key: "fld_type_of_house_name", text: "Type of House" },
          { key: "fld_over_crowding_name", text: "Overcrowding" },
          { key: "fld_ventilation_name", text: "Ventilation" },
          { key: "fld_lighting_name", text: "Lighting" },
          { key: "fld_access_electricty_name", text: "Access to Electricity" },
          { key: "fld_num_electricty_house", text: "Number of Electricity Connections" },
          { key: "fld_acc_potbl_wat_name", text: "Access to Potable Water" },
          { key: "fld_kitchen_name", text: "Kitchen" },
          { key: "fld_cooking_fuel_used_name", text: "Cooking Fuel Used" },
          { key: "fld_cooking_fuel_other", text: "Cooking Fuel Other" },
          { key: "fld_smoke_outlet_name", text: "Smoke Outlet" },
          { key: "fld_source_water_supply_name", text: "Source of Water Supply" },
          { key: "fld_other_water_supply", text: "Other Source of Water Supply" },
          { key: "fld_water_supply_timing_name", text: "Water Supply Timing" },
          { key: "fld_storage_of_water_name", text: "Storage of Water" },
          { key: "fld_other_water_storage", text: "Other Storage of Water" },
          { key: "fld_water_used_drinking_name", text: "Water Used for Drinking" },
          { key: "fld_other_drinking_water_used", text: "Other Water Used for Drinking" },
          { key: "fld_toilet_facility_name", text: "Toilet Facility" },
          { key: "fld_water_supply_toilet_name", text: "Water Supply for Toilet" },
          { key: "fld_handwashing_fac_toilet_use_name", text: "Handwashing Facility in Toilet" },
          { key: "fld_pres_insect_flies_rodent_name", text: "Presence of Vectors" },
          { key: "fld_pres_domestic_animal_name", text: "Presence of Domestic Animals" },
          { key: "fld_domestic_animal_name", text: "Domestic Animal Name" },
          // { key: "fld_animalwaste_dispo_method", text: "Animal Waste Disposal Method" },
          { key: "fld_pres_pet_animal_name", text: "Presence of Pet Animals" },
          { key: "fld_pet_animal", text: "Pet Animal Details" },
          { key: "fld_segrr_wastes_name", text: "Waste Segregation" },
          { key: "fld_waste_bins_with_lid_name", text: "Waste Bins with Lid" },
          { key: "fld_waste_disposal_name", text: "Waste Disposal Method" },
          { key: "fld_any_vect_breeding_site_name", text: "Vector Breeding Site" },
          { key: "fld_conf_disagreement_family_name", text: "Conflicts in Family" },
          { key: "fld_dom_voilence_women_name", text: "Domestic Violence (Women)" },
          { key: "fld_type_voilence_name", text: "Type of  Violence" },
          // { key: "fld_how_fam_resolve_confl_name", text: "Family Conflict Resolution" },
          // { key: "fld_relation_with_relatives_name", text: "Relations with Relatives" },
          // { key: "fld_interaction_with_neighbour_name", text: "Interaction with Neighbors" },
          { key: "fld_person_with_disability_name", text: "Person with Disability" },
          { key: "fld_natural_disability", text: "Nature of Disability" },
          { key: "fld_soc_helth_problem_fac_famliy_name", text: "Social Health Problems in Family" },
          { key: "fld_sys_medi_prefered_name", text: "Preferred Medical System" },
          { key: "fld_sys_other_medi_prefered", text: "Other Preferred Medical System" },
          { key: "fld_near_hc_fac_avil_famliy", text: "Nearby Health Facility" },
          { key: "fld_distance_facility", text: "Distance to Facility" },
          { key: "fld_transport_faci_mode_name", text: "Transport Facility Mode" },
          { key: "fld_near_gov_health_famliy_name", text: "Nearby Govt Facility" },
          { key: "fld_other_facility", text: "Other Facility" },
          { key: "fld_num_family_mem_abha", text: "Number of Family Members with ABHA" },
          { key: "fld_access_digital_service_name", text: "Access to Digital Services" },
          { key: "fld_health_insurance_coverage_name", text: "Health Insurance Coverage" },
          { key: "fld_type_insurance_name", text: "If Yes, Type of Insurance" },
          { key: "fld_access_financial_service_name", text: "Access to Financial Services" },
          { key: "fld_geo_x", text: "Geo X (Longitude)" },
          { key: "fld_geo_y", text: "Geo Y (Latitude)" },
      ],
      tab2Data:[
        { key: "slNo", text: "Sl No" },
        // { key: "fld_asha_name", text: "Name of the Interviewer" },
        // { key: "fld_state_name", text: "State Name" },
        // { key: "fld_district_name", text: "District Name" },
        // { key: "fld_taluk_name", text: "Taluk Name" },
        // { key: "fld_village_name", text: "Village Name" },
        // { key: "fld_serial_no", text: "" },
        { key: "fld_is_loggedin_user_id", text: "User ID" },
        { key: "fld_hh_id", text: "HH ID" },
        // { key: "fld_member_id", text: "Member ID" },
        { key: "fld_date_of_visit", text: "Date of visit" },
        { key: "fld_individual_or_child_name", text: "Name of the Individual" },
        { key: "fld_aadhar_no", text: "Aadhar No" },
        { key: "fld_dob", text: "Date of Birth" },
        { key: "fld_age_years", text: "Age (In years)" },
        { key: "fld_age_month", text: "Age (In Month)" },
        { key: "fld_gender_name", text: "Gender" },
        { key: "fld_relation_to_hh_name", text: "Relation to Head of Family" },
        { key: "fld_relation_other_specify", text: "Other (specify)" },
        { key: "fld_marital_status_name", text: "Marital Status" },
        { key: "fld_atained_menarchy_name", text: "Atained menarchy" },
        { key: "fld_regular_cycles_name", text: "if yes,regularity of the cycles" },
        { key: "fld_menstruation_problem_specify", text: "Any problems associated with menstruation …specify" },
        { key: "fld_preg_status_name", text: "Pregnancy status" },
        { key: "fld_thayi_card_name", text: "Check the Thayi card" },
        { key: "fld_already_having_child_name", text: "Already having children" },
        { key: "fld_how_many_children", text: "How many children?" },
        { key: "fld_any_abortions_name", text: "Any abortions? " },
        { key: "fld_how_many_abortions", text: "How many abortions?" },
        // { key: "fld_obstetric_score_name", text: "Obstetric score" },
        // { key: "fld_score_name", text: "Score" },
        { key: "fld_when_was_first_child_born", text: "When was the first Child Born" },
        { key: "fld_problem_faced_previous_preg_name", text: "Any problems faced during previous pregnancy?" },
        { key: "fld_problem_faced_other_spcify", text: "Other (specify)" },
        { key: "fld_problem_faced_name", text: "Problem faced" },
        { key: "fld_attained_masopause_name", text: "attained menopause" },
        { key: "fld_any_health_issue_menopause", text: "Any health issues related to menopause" },
        { key: "fld_diet_name", text: "Diet" },
        { key: "fld_education_name", text: "Education" },
        { key: "fld_occupation_name", text: "Occupation" },
        { key: "fld_other_occupation", text: "Other Occupation" },
        // { key: "fld_income", text: "Income" },
        // { key: "fld_hobies_any_name", text: "Hobies ( if any)" },
        // { key: "fld_other_hobies", text: "Other Hobies" },
        { key: "fld_height_in_cm", text: "Height (in cm)" },
        { key: "fld_weight_in_kg", text: "Weight (in kg)" },
        { key: "fld_bmi", text: "BMI" },
        { key: "fld_bmi_interpretation", text: "BMI Interpretation" },
        { key: "fld_waist_in_inch", text: "Waist ( in inch)" },
        { key: "fld_waist_interpretation", text: "Interpretation (Waist)" },
        { key: "fld_blood_pressure", text: "Blood Pressure" },
        { key: "fld_blood_group_name", text: "Blood Group" },
        { key: "fld_any_family_ho_chr_dis_name", text: "Is any Family H/O chronic diseases" },
        { key: "fld_fam_ho_chronic_dise_name", text: "Family H/O chronic diseases" },
        { key: "fld_chronic_dise_others_specify", text: "Other specify" },
        { key: "fld_having_inherited_desease_name", text: "Are you having any inherited disease?" },
        { key: "fld_disease_specify", text: "Specify Disease" },
        { key: "fld_self_repo_chronic_cond_name", text: "Self-reported chronic conditions" },
        { key: "fld_diabetes_since_when", text: "Since when?(Diabetes)" },
        { key: "fld_diabetes_medication_name", text: "On medication (Diabetes)" },
        { key: "fld_hypertension_since_when", text: "Since when?(Hypertension)" },
        { key: "fld_hytension_medtion_name", text: "On medication (Hypertension)" },
        { key: "fld_heart_dis_since_when", text: "Since when?(Heart disease/ Heart attack)" },
        { key: "fld_heart_dis_medtion_name", text: "On medication (Heart disease/ Heart attack)" },
        { key: "fld_asthama_since_when", text: "Since when?(Asthma)" },
        { key: "fld_asthama_medication_name", text: "On medication (Asthma)" },
        { key: "fld_kidn_dis_since_when", text: "Since when?(Kidney disease)" },
        { key: "fld_kidn_dis_medition_name", text: "On medication (Kidney disease)" },
        { key: "fld_arthritis_since_when", text: "Since when?(Arthritis)" },
        { key: "fld_arthritis_medication_name", text: "On medication (Arthritis)" },
        { key: "fld_cvd_stroke_since_when", text: "Since when?(Cvd, stroke)" },
        { key: "fld_cvd_stroke_medication_name", text: "On medication (Cvd, stroke)" },
        { key: "fld_cancer_type", text: "Type of Cancer" },
        { key: "fld_cancer_since_when", text: "Since when?(Cancer)" },
        { key: "fld_cancer_und_trtment_name", text: "Under treatment?(Cancer)" },
        { key: "fld_cancer_any_surges_name", text: "Any surgeries?(Cancer)" },
        { key: "fld_self_repot_other_specify", text: "Other specify" },
        { key: "fld_other_specify_since_when", text: "Since when?(Other specify)" },
        { key: "fld_other_specify_medition_name", text: "On medication (Other specify)" },
        { key: "fld_self_rep_inf_dis_pst_1_year_name", text: "Self-reported infectious diseases in the recent past 1 year (check records if available)" },
        { key: "fld_self_rep_inf_dis_others_sy", text: "Any other specify" },
        { key: "fld_self_reported_allergies_name", text: "Self-reported Allergies" },
        { key: "fld_allergies_others_specify", text: "Other specify" },
        { key: "fld_self_repo_reprod_health_issue", text: "Self-reported reproductive health issues (PCOS, infertility, PID etc)" },
        { key: "fld_diet_under_5_name", text: "Diet under 5" },
        { key: "fld_feeding_status_name", text: "Feeding status" },
        { key: "fld_education_under_5_name", text: "Education under 5" },
        { key: "fld_anganwadi_school_name", text: "Name of the Anganwadi/ School (Children only)" },
        { key: "fld_mid_upper_arm_cirference", text: "Mid upper arm circumference (in cm)" },
        { key: "fld_head_circumference", text: "Head Circumference ( in cm)" },
        { key: "fld_chest_circumference", text: "Chest Circumference (in cm)" },
        { key: "fld_immunisation_status_name", text: "Immunisation Status (Check Immunisation/ Thaayi Card)" },
        { key: "fld_due_date_nxt_vaccination", text: "Due Date For next vaccination?" },
        { key: "fld_weight_for_age_who_name", text: "Weight for Age (WHO)Mention S.D as per chart" },
        { key: "fld_height_for_age_who_name", text: "Height for Age (WHO) Mention S.D as per chart" },
        // { key: "fld_weight_for_height_who_name", text: "Weight for Height (WHO) Mention S.D as per chart" },
        { key: "fld_oral_check_up", text: "Oral Check up" },
        { key: "fld_any_chronic_illness", text: "Any Chronic Illness" },
        { key: "fld_any_hospltion_past_1_year", text: "Any hospitalisation in the past 1 year" },
        { key: "fld_history_repe_infec_pst_1_year", text: "Any history of repeated infections in the past 1 year" },
        { key: "fld_geo_x", text: "Geo X (Longitude)" },
          { key: "fld_geo_y", text: "Geo Y (Latitude)" },
      ],
      tab3Data: [
          { key: "slNo", text: "Sl No" },
          { key: "fld_is_loggedin_user_id", text: "User ID" },
          { key: "fld_hh_id", text: "HH ID" },
          { key: "fld_member_id", text: "Member ID" },
          { key: "fld_state_name", text: "State Name" },
          { key: "fld_district_name", text: "District Name" },
          { key: "fld_taluk_name", text: "Taluk Name" },
          { key: "fld_village_name", text: "Village Name" },
          { key: "fld_date_of_visit", text: "Date of visit" },
          { key: "fld_asha_name", text: "Name of the Interviewer" },
          { key: "fld_individual_or_child_name", text: "Individual child name" },
          { key: "fld_diet_name", text: "Diet name" },
          { key: "fld_stpl_dt_vagetable_intake_name", text: "Vegetable intake" },
          { key: "fld_veg_intake_frequency_name", text: "Vegetable intake frequency" },
          { key: "fld_stpl_dt_green_veg_intake_name", text: "Green Vegetable intake" },
          { key: "fld_green_veg_intake_frequency_name", text: "Green Vegetable intake frequency" },
          { key: "fld_stpl_dt_fruits_intake_name", text: "Fruits intake" },
          { key: "fld_fruits_intake_frequency_name", text: "Fruits intake frequency" },
          { key: "fld_junk_food_intake_name", text: "Fast food intake" },
          { key: "fld_junk_food_intake_frequency_name", text: "Junk food intake frequency" },
          // { key: "fld_stpl_dt_suger_drnk_intake_name", text: "Sugar drink intake" },
          // { key: "fld_stpl_dt_suger_drnk_intake_name", text: "Sugar drink intake frequency" },
          { key: "fld_crls_grains_frm_item_name", text: "Cereals and grains and food made from these items" },
          { key: "fld_crls_grains_frm_item_frequency_name", text: "Cereals and grains and food made from these items Frequency" },
          { key: "fld_vitamin_a_rich_plant_food_name", text: "Vitamin A rich plant foods" },
          { key: "fld_vitamin_a_rich_plant_food_frequiency_name", text: "Vitamin A rich plant foods frequency" },
          { key: "fld_egg_name", text: "Egg" },
          { key: "fld_egg_frequency_name", text: "Egg Frequency" },
          { key: "fld_pulses_legums_name", text: "Pulses/legumes" },
          { key: "fld_pulses_legums_frequency_name", text: "Pulses/legumes Frequency" },
          { key: "fld_nuts_seeds_name", text: "Nuts and Seeds" },
          { key: "fld_nuts_seeds_frequency_name", text: "Nuts and Seeds Frequency" },
          { key: "fld_milk_product_name", text: "Milk and Milk products" },
          { key: "fld_milk_product_fequency_name", text: "Milk and Milk products Frequency" },
          { key: "fld_meat_product_name", text: "Meat and Meat products" },
          { key: "fld_meat_product_frequency_name", text: "Meat and Meat products Frequency" },
          { key: "fld_fish_other_sea_food_name", text: "Fish and other sea foods" },
          { key: "fld_fish_other_sea_food_frequency_name", text: "Fish and other sea foods Frequency" },
          { key: "fld_any_ph_activity_name", text: "Any Physical Activity" },
          { key: "fld_pa_frequency_name", text: "Physical activity Frequency" },
          { key: "fld_pa_type_name", text: "Physical activity name" },
          { key: "fld_pa_other", text: "Other physical activity" },
          { key: "fld_sub_smoke_sta_tobco_use_name", text: "Smoking status/tobacco use" },
          { key: "fld_sub_smokeless_tobco_name", text: "Smokeless tobacco use" },
          { key: "fld_sub_consuptn_name", text: "Alcohol consumption" },
          { key: "fld_sub_am_fmly_use_illict_drug_name", text: "Any member in family uses any illicit drugs?" },
          { key: "fld_sub_specify_what", text: "Yes, Specify what?" },
          { key: "fld_sleep_duration_avg_night_name", text: "How many hours of sleep do you get on an average night?" },
          { key: "fld_sleep_quality_rate_name", text: "How would you rate the quality of your sleep?" },
          { key: "fld_reg_hc_vis_hp_routine_chkp_name", text: "How often do you visit a healthcare provider for a routine check-up?" },
          { key: "fld_str_mngmt_eng_act_mng_stress_name", text: "How often do you engage in activities to manage stress (e.g., meditation, hobbies)?" },
          { key: "fld_mhsu_have_evr_soght_help_mntl_helth_name", text: "Have you ever sought help from a mental health professional (e.g., counselor, therapist)?" },
          { key: "fld_please_specify_name", text: "Please specify" },
          { key: "fld_seat_belt_use_when_driving_car_name", text: "How often do you wear a seatbelt when driving or riding in a car?" },
          { key: "fld_helmet_use_when_riding_motorcycle_name", text: "How often do you wear a helmet when riding a bicycle, motorcycle, or engaging in similar activities?" },
          { key: "fld_geo_x", text: "Geo X (Longitude)" },
          { key: "fld_geo_y", text: "Geo Y (Latitude)" },
      ],
      tab4Data: [
          { key: "slNo", text: "Sl No" },
          { key: "fld_is_loggedin_user_id", text: "User ID" },
          { key: "fld_hh_id", text: "HH ID" },
          { key: "fld_member_id", text: "Member ID" },
          { key: "fld_date_of_visit", text: "Date of visit" },
          { key: "fld_asha_name", text: "Name of the Interviewer" },
          { key: "fld_state_name", text: "State Name" },
          { key: "fld_district_name", text: "District Name" },
          { key: "fld_taluk_name", text: "Taluk Name" },
          { key: "fld_village_name", text: "Village Name" },
          { key: "fld_individual_or_child_name", text: "Individual or Child name" },
          { key: "fld_beli_influ_sprit_relg_ph_name", text: "Do you believe in the influence of spiritual or religious practices on health (e.g., prayer, rituals)?" },
          { key: "fld_hhd_mak_who_makes_dec_in_HH_name", text: "Who makes the health-related decisions in your household?" },
          { key: "fld_other_family_member", text: "Other family members (Please specify)" },
          { key: "fld_gender_impt_access_hc_name", text: "Do you feel that traditional gender roles impact your access to healthcare?" },
          { key: "fld_cult_soco_pract_woman_helth_in_comity_name", text: "Are there any cultural or social practices that affect women's health in your family? (e.g., menstrual taboos, preference for male children)" },
          { key: "fld_cultural_social_practices", text: "Cultural or social practices" },
          { key: "fld_expo_polltts_yur_home_nehod_name", text: "Are you exposed to any of the following pollutants in your home or neighbourhood? (Check all that apply)" },
          { key: "fld_other_expo_pollutants", text: "Other (Please specify)" },
          { key: "fld_waterborne_dieseas_name", text: "Waterborne diseases" },
          // { key: "fld_other_expfld_waterborne_dieseas_nameo_pollutants", text: "Have you or anyone in your household been affected by waterborne diseases (e.g., cholera, diarrhea)? In the past 6 months" },
          { key: "fld_occupation_hazard_name", text: "Are you exposed to any of the following occupational hazards? (Check all that apply)" },
          { key: "fld_geo_x", text: "Geo X (Longitude)" },
          { key: "fld_geo_y", text: "Geo Y (Latitude)" },
      ],
      tab5Data: [
        { key: "slNo", text: "Sl No" },
        { key: "fld_is_loggedin_user_id", text: "User ID" },
          { key: "fld_hh_id", text: "HH ID" },
          { key: "fld_member_id", text: "Member ID" },
          { key: "fld_date_of_visit", text: "Date of visit" },
        { key: "fld_asha_name", text: "Name of the Interviewer" },
        { key: "fld_state_name", text: "State Name" },
        { key: "fld_district_name", text: "District Name" },
        { key: "fld_taluk_name", text: "Taluk Name" },
        { key: "fld_village_name", text: "Village Name" },
        { key: "fld_individual_or_child_name", text: "Individual or Child name" },
        { key: "fld_access_transport_name", text: "Access to Transportation" },
        { key: "fld_other_access_transpot", text: "Other (Please specify)" },
        { key: "fld_how_secure_feel_curt_job_name", text: "How secure do you feel in your current job?" },
        { key: "fld_have_any_saving_name", text: "Do you have any savings?" },
        { key: "fld_currently_in_debt_name", text: "Are you currently in debt?" },
        { key: "fld_geo_x", text: "Geo X (Longitude)" },
        { key: "fld_geo_y", text: "Geo Y (Latitude)" },
    ],
      tab6Data: [
        { key: "slNo", text: "Sl No" },
        { key: "fld_is_loggedin_user_id", text: "User ID" },
        { key: "fld_hh_id", text: "HH ID" },
        { key: "fld_member_id", text: "Member ID" },
        { key: "fld_date_of_visit", text: "Date of visit" },
        { key: "fld_asha_name", text: "Name of the Interviewer" },
        { key: "fld_state_name", text: "State Name" },
        { key: "fld_district_name", text: "District Name" },
        { key: "fld_taluk_name", text: "Taluk Name" },
        { key: "fld_village_name", text: "Village Name" },
        { key: "fld_individual_or_child_name", text: "Individual or Child name" },
        // { key: "fld_when_last_helth_chkup_name", text: "When was your last health check up?" },
        { key: "fld_when_last_helth_chkup", text: "When was your last health check up?" },
        { key: "fld_hc_facility_available_name", text: "Health care facilities available" },
        { key: "fld_pref_which_hc_facility_name", text: "Preferred to use which health care facility" },
        { key: "fld_near_hcf_frm_home_name", text: "How far is the nearest healthcare facility from your home?" },
        { key: "fld_how_log_wait_hcp_name", text: "How long do you usually wait to see a healthcare provider after arriving at the facility?" },
        { key: "fld_rate_affordability_hsc_name", text: "How would you rate the affordability of healthcare services in your area?" },
        { key: "fld_can_afford_hc_needed_name", text: "Can you afford healthcare when needed?" },
        { key: "fld_do_hav_health_insurance_name", text: "Do you have health insurance?" },
        { key: "fld_how_much_spend_each_mnth_name", text: "How much do you typically spend on healthcare out-of-pocket each month?" },
        { key: "fld_barries_healthcare_name", text: "What are the main barriers to accessing healthcare for you? (Check all that apply)" },
        { key: "fld_other_sy_barries_healthcare", text: "Other (Please specify)" },
        { key: "fld_geo_x", text: "Geo X (Longitude)" },
        { key: "fld_geo_y", text: "Geo Y (Latitude)" },
    ],
      tab7Data: [
        { key: "slNo", text: "Sl No" },
        { key: "fld_is_loggedin_user_id", text: "User ID" },
        // { key: "fld_hh_id", text: "HH ID" },
        // { key: "fld_member_id", text: "Member ID" },
        // { key: "fld_date_of_visit", text: "Date of visit" },
        { key: "fld_asha_name", text: "Name of the Interviewer" },
        { key: "fld_village_name", text: "Village Name" },
        {key: "fld_location_capture_date",text: "Location Geo cpture date"},
        { key:"fld_location_type_name",text: "Location Type"},
        { key:"fld_levels_of_education_name",text: "Levels of education"},
        { key:"fld_type_edu_inst_name",text: "Type Educational institutions"},
        { key:"fld_name_of_edu_institution",text: "Name Of the Educational institutions"},
        { key:"fld_health_care_delivery_sys_name",text: "Health care delivery systems"},
        { key:"fld_govt_hospital_type_name",text: "Type of Govt Hospital"},
        { key:"fld_name_of_healthcare_center",text: "Name Of the Health care center"},
        { key:"fld_num_doctor",text: "Doctors"},
        { key:"fld_num_nurse",text: "Nurses"},
        { key:"fld_num_anm",text: "ANM"},
        { key:"fld_num_asha",text: "ASHA"},
        { key:"fld_details_of_service_name",text: "Details of services"},
        { key:"fld_any_other_specify",text: "Others(Specify)"},
        { key:"fld_water_source_type_name",text: "Water source Type"},
        { key:"fld_water_quality_parameter",text: "Water quality parameters"},
        { key:"fld_relegious_place_type_name",text: "Religious places Type"},
        { key:"fld_recreat_entertainmnt_type_name",text: "Recreational and entertainment Type"},
        { key:"fld_recreat_entertainmnt_name",text: "Recreational and entertainment name"},
        { key:"fld_community_facility_type_name",text: "Community facilities Type"},
        { key:"fld_community_facility_name",text: "Community facilities name"},
        { key:"fld_waste_managment_type_name",text: "Waste management facilities Type"},
        { key:"fld_waste_managment_name",text: "Waste management facilities  Name"},
        { key:"fld_mar_fruit_veg_meat_pltry_area",text: "Market, fruits and vegetable, meat and poultry Area"},
        { key:"fld_transportation_type_name",text: "Transportation Type"},
        { key:"fld_transportation_name",text: "Transportation Name"},
        { key:"fld_any_other_details_location",text: "Any Other Details for Location"},
        { key: "fld_geo_x", text: "Geo X (Longitude)" },
        { key: "fld_geo_y", text: "Geo Y (Latitude)" },
    ],
    };









  const rawData = async () => {
    try {
      setLoading(true);
      const requestData = {
        fld_asha_id: "admin",
        login_user_id: "admin",
        request_tables: {
          trn_tbl_hh_reg_form: "",
          trn_tbl_individual_details: "",
          trn_tbl_hh_behavioral_determinants_form: "",
          trn_tbl_hh_socio_cultural_form: "",
          trn_tbl_economic_determinants_form: "",
          trn_tbl_health_service_form: "",
          trn_tbl_location_geo_mapping: "",
        },
        synceddatetime: "2023-01-24 11:57:34",
        FormCode: "314",
        ApiKey: "kavin",
        AppTypeNo: "3",
        AppVersion: "1.0.0",
      };
      const result = await api.get_RawData_Report(requestData);
      const responseData = result.responsemessage;
      if (result && result.status === "1") {
        //tab1
        setTab1Data(responseData.trn_tbl_hh_reg_form);
        const Tab1Slno = result.responsemessage.trn_tbl_hh_reg_form.map(
          (role, index) => ({ ...role, slNo: index + 1 ,
            fld_asha_name: replaceValue(role.fld_asha_name),
            fld_state_name: replaceValue(role.fld_state_name),
            fld_district_name: replaceValue(role.fld_district_name),
            fld_taluk_name: replaceValue(role.fld_taluk_name),
            fld_village_name: replaceValue(role.fld_village_name),
            fld_informant_name: replaceValue(role.fld_informant_name),
            fld_family_head_name: replaceValue(role.fld_family_head_name),
            fld_phone_no_1: replaceValue(role.fld_phone_no_1),
            fld_phone_no_2: replaceValue(role.fld_phone_no_2),
            fld_address: replaceValue(role.fld_address),
            fld_residing_year_since: replaceValue(role.fld_residing_year_since),
            fld_previous_residence: replaceValue(role.fld_previous_residence),
            fld_native_place: replaceValue(role.fld_native_place),
            fld_reason_for_migration_name: replaceValue(role.fld_reason_for_migration_name),
            fld_other_reason: replaceValue(role.fld_other_reason),
            fld_landmark: replaceValue(role.fld_landmark),
            fld_type_of_family_name: replaceValue(role.fld_type_of_family_name),
            fld_other_social_sevice_card: replaceValue(role.fld_other_social_sevice_card),
            fld_card_holder_status_name: replaceValue(role.fld_card_holder_status_name),
            fld_total_family_member: replaceValue(role.fld_total_family_member),
            fld_total_family_income: replaceValue(role.fld_total_family_income),
            fld_any_property_own_Name: replaceValue(role.fld_any_property_own_Name),
            fld_property_details_name: replaceValue(role.fld_property_details_name),
            fld_socio_status_name: replaceValue(role.fld_socio_status_name),
            fld_religion_name: replaceValue(role.fld_religion_name),
            fld_other_religion: replaceValue(role.fld_other_religion),
            fld_caste_name: replaceValue(role.fld_caste_name),
            fld_rec_vtl_event_family_name: replaceValue(role.fld_rec_vtl_event_family_name),
            fld_others_event: replaceValue(role.fld_others_event),
            fld_ownership_of_house_name: replaceValue(role.fld_ownership_of_house_name),
            fld_others_ownership: replaceValue(role.fld_others_ownership),
            fld_family_cons_environmental_name: replaceValue(role.fld_family_cons_environmental_name),
            fld_detail_cons_environmental_name: replaceValue(role.fld_detail_cons_environmental_name),
            fld_open_drains_name: replaceValue(role.fld_open_drains_name),
            fld_type_of_house_name: replaceValue(role.fld_type_of_house_name),
            fld_over_crowding_name: replaceValue(role.fld_over_crowding_name),
            fld_ventilation_name: replaceValue(role.fld_ventilation_name),
            fld_lighting_name: replaceValue(role.fld_lighting_name),
            fld_access_electricty_name: replaceValue(role.fld_access_electricty_name),
            fld_num_electricty_house: replaceValue(role.fld_num_electricty_house),
            fld_acc_potbl_wat_name: replaceValue(role.fld_acc_potbl_wat_name),
            fld_kitchen_name: replaceValue(role.fld_kitchen_name),
            fld_cooking_fuel_used_name: replaceValue(role.fld_cooking_fuel_used_name),
            fld_cooking_fuel_other: replaceValue(role.fld_cooking_fuel_other),
            fld_smoke_outlet_name: replaceValue(role.fld_smoke_outlet_name),
            fld_source_water_supply_name: replaceValue(role.fld_source_water_supply_name),
            fld_other_water_supply: replaceValue(role.fld_other_water_supply),
            fld_water_supply_timing_name: replaceValue(role.fld_water_supply_timing_name),
            fld_storage_of_water_name: replaceValue(role.fld_storage_of_water_name),
            fld_other_water_storage: replaceValue(role.fld_other_water_storage),
            fld_water_used_drinking_name: replaceValue(role.fld_water_used_drinking_name),
            fld_other_drinking_water_used: replaceValue(role.fld_other_drinking_water_used),
            fld_toilet_facility_name: replaceValue(role.fld_toilet_facility_name),
            fld_water_supply_toilet_name: replaceValue(role.fld_water_supply_toilet_name),
            fld_handwashing_fac_toilet_use_name: replaceValue(role.fld_handwashing_fac_toilet_use_name),
            fld_pres_insect_flies_rodent_name: replaceValue(role.fld_pres_insect_flies_rodent_name),
            fld_pres_domestic_animal_name: replaceValue(role.fld_pres_domestic_animal_name),
            fld_domestic_animal_name: replaceValue(role.fld_domestic_animal_name),
            // fld_animalwaste_dispo_method: replaceValue(role.fld_animalwaste_dispo_method),
            fld_pres_pet_animal_name: replaceValue(role.fld_pres_pet_animal_name),
            fld_pet_animal: replaceValue(role.fld_pet_animal),
            fld_segrr_wastes_name: replaceValue(role.fld_segrr_wastes_name),
            fld_waste_bins_with_lid_name: replaceValue(role.fld_waste_bins_with_lid_name),
            fld_waste_disposal_name: replaceValue(role.fld_waste_disposal_name),
            fld_any_vect_breeding_site_name: replaceValue(role.fld_any_vect_breeding_site_name),
            fld_conf_disagreement_family_name: replaceValue(role.fld_conf_disagreement_family_name),
            fld_dom_voilence_women_name: replaceValue(role.fld_dom_voilence_women_name),
            fld_type_voilence_name: replaceValue(role.fld_type_voilence_name),
            // fld_how_fam_resolve_confl_name: replaceValue(role.fld_how_fam_resolve_confl_name),
            // fld_relation_with_relatives_name: replaceValue(role.fld_relation_with_relatives_name),
            // fld_interaction_with_neighbour_name: replaceValue(role.fld_interaction_with_neighbour_name),
            fld_person_with_disability_name: replaceValue(role.fld_person_with_disability_name),
            fld_natural_disability: replaceValue(role.fld_natural_disability),
            fld_soc_helth_problem_fac_famliy_name: replaceValue(role.fld_soc_helth_problem_fac_famliy_name),
            fld_sys_medi_prefered_name: replaceValue(role.fld_sys_medi_prefered_name),
            fld_sys_other_medi_prefered: replaceValue(role.fld_sys_other_medi_prefered),
            fld_near_hc_fac_avil_famliy: replaceValue(role.fld_near_hc_fac_avil_famliy),
            fld_distance_facility: replaceValue(role.fld_distance_facility),
            fld_transport_faci_mode_name: replaceValue(role.fld_transport_faci_mode_name),
            fld_near_gov_health_famliy_name: replaceValue(role.fld_near_gov_health_famliy_name),
            fld_other_facility: replaceValue(role.fld_other_facility),
            fld_num_family_mem_abha: replaceValue(role.fld_num_family_mem_abha),
            fld_access_digital_service_name: replaceValue(role.fld_access_digital_service_name),
            fld_health_insurance_coverage_name: replaceValue(role.fld_health_insurance_coverage_name),
            fld_type_insurance_name: replaceValue(role.fld_type_insurance_name),
            fld_access_financial_service_name: replaceValue(role.fld_access_financial_service_name),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),

          })
        );
        setTab1Data(Tab1Slno);
        //tab2
        setTab2Data(responseData.trn_tbl_individual_details);
        const Tab2Slno = result.responsemessage.trn_tbl_individual_details.map(
          (role, index) => ({ ...role, slNo: index + 1 ,
            fld_date_of_visit: replaceValue(role.fld_date_of_visit),
            fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
            fld_aadhar_no: replaceValue(role.fld_aadhar_no),
            fld_dob: replaceValue(role.fld_dob),
            fld_age_years: replaceValue(role.fld_age_years),
            fld_age_month: replaceValue(role.fld_age_month),
            fld_gender_name: replaceValue(role.fld_gender_name),
            fld_relation_to_hh_name: replaceValue(role.fld_relation_to_hh_name),
            fld_relation_other_specify: replaceValue(role.fld_relation_other_specify),
            fld_marital_status_name: replaceValue(role.fld_marital_status_name),
            fld_atained_menarchy_name: replaceValue(role.fld_atained_menarchy_name),
            fld_regular_cycles_name: replaceValue(role.fld_regular_cycles_name),
            fld_menstruation_problem_specify: replaceValue(role.fld_menstruation_problem_specify),
            fld_preg_status_name: replaceValue(role.fld_preg_status_name),
            fld_thayi_card_name: replaceValue(role.fld_thayi_card_name),
            fld_already_having_child_name: replaceValue(role.fld_already_having_child_name),
            fld_how_many_children: replaceValue(role.fld_how_many_children),
            fld_any_abortions_name: replaceValue(role.fld_any_abortions_name),
            fld_how_many_abortions: replaceValue(role.fld_how_many_abortions),
            // fld_obstetric_score_name: replaceValue(role.fld_obstetric_score_name),
            // fld_score_name: replaceValue(role.fld_score_name),
            fld_when_was_first_child_born: replaceValue(role.fld_when_was_first_child_born),
            fld_problem_faced_previous_preg_name: replaceValue(role.fld_problem_faced_previous_preg_name),
            fld_problem_faced_other_spcify: replaceValue(role.fld_problem_faced_other_spcify),
            fld_problem_faced_name: replaceValue(role.fld_problem_faced_name),
            fld_attained_masopause_name: replaceValue(role.fld_attained_masopause_name),
            fld_any_health_issue_menopause: replaceValue(role.fld_any_health_issue_menopause),
            fld_diet_name: replaceValue(role.fld_diet_name),
            fld_education_name: replaceValue(role.fld_education_name),
            fld_occupation_name: replaceValue(role.fld_occupation_name),
            fld_other_occupation: replaceValue(role.fld_other_occupation),
            // fld_income: replaceValue(role.fld_income),
            // fld_hobies_any_name: replaceValue(role.fld_hobies_any_name),
            // fld_other_hobies: replaceValue(role.fld_other_hobies),
            fld_height_in_cm: replaceValue(role.fld_height_in_cm),
            fld_weight_in_kg: replaceValue(role.fld_weight_in_kg),
            fld_bmi: replaceValue(role.fld_bmi),
            fld_bmi_interpretation: replaceValue(role.fld_bmi_interpretation),
            fld_waist_in_inch: replaceValue(role.fld_waist_in_inch),
            fld_waist_interpretation: replaceValue(role.fld_waist_interpretation),
            fld_blood_pressure: replaceValue(role.fld_blood_pressure),
            fld_blood_group_name: replaceValue(role.fld_blood_group_name),
            fld_any_family_ho_chr_dis_name: replaceValue(role.fld_any_family_ho_chr_dis_name),
            fld_fam_ho_chronic_dise_name: replaceValue(role.fld_fam_ho_chronic_dise_name),
            fld_chronic_dise_others_specify: replaceValue(role.fld_chronic_dise_others_specify),
            fld_having_inherited_desease_name: replaceValue(role.fld_having_inherited_desease_name),
            fld_disease_specify: replaceValue(role.fld_disease_specify),
            fld_self_repo_chronic_cond_name: replaceValue(role.fld_self_repo_chronic_cond_name),
            fld_diabetes_since_when: replaceValue(role.fld_diabetes_since_when),
            fld_diabetes_medication_name: replaceValue(role.fld_diabetes_medication_name),
            fld_hypertension_since_when: replaceValue(role.fld_hypertension_since_when),
            fld_hytension_medtion_name: replaceValue(role.fld_hytension_medtion_name),
            fld_heart_dis_since_when: replaceValue(role.fld_heart_dis_since_when),
            fld_heart_dis_medtion_name: replaceValue(role.fld_heart_dis_medtion_name),
            fld_asthama_since_when: replaceValue(role.fld_asthama_since_when),
            fld_asthama_medication_name: replaceValue(role.fld_asthama_medication_name),
            fld_kidn_dis_since_when: replaceValue(role.fld_kidn_dis_since_when),
            fld_kidn_dis_medition_name: replaceValue(role.fld_kidn_dis_medition_name),
            fld_arthritis_since_when: replaceValue(role.fld_arthritis_since_when),
            fld_arthritis_medication_name: replaceValue(role.fld_arthritis_medication_name),
            fld_cvd_stroke_since_when: replaceValue(role.fld_cvd_stroke_since_when),
            fld_cvd_stroke_medication_name: replaceValue(role.fld_cvd_stroke_medication_name),
            fld_cancer_type: replaceValue(role.fld_cancer_type),
            fld_cancer_since_when: replaceValue(role.fld_cancer_since_when),
            fld_cancer_und_trtment_name: replaceValue(role.fld_cancer_und_trtment_name),
            fld_cancer_any_surges_name: replaceValue(role.fld_cancer_any_surges_name),
            fld_self_repot_other_specify: replaceValue(role.fld_self_repot_other_specify),
            fld_other_specify_since_when: replaceValue(role.fld_other_specify_since_when),
            fld_other_specify_medition_name: replaceValue(role.fld_other_specify_medition_name),
            fld_self_rep_inf_dis_pst_1_year_name: replaceValue(role.fld_self_rep_inf_dis_pst_1_year_name),
            fld_self_rep_inf_dis_others_sy: replaceValue(role.fld_self_rep_inf_dis_others_sy),
            fld_self_reported_allergies_name: replaceValue(role.fld_self_reported_allergies_name),
            fld_allergies_others_specify: replaceValue(role.fld_allergies_others_specify),
            fld_self_repo_reprod_health_issue: replaceValue(role.fld_self_repo_reprod_health_issue),
            fld_diet_under_5_name: replaceValue(role.fld_diet_under_5_name),
            fld_feeding_status_name: replaceValue(role.fld_feeding_status_name),
            fld_education_under_5_name: replaceValue(role.fld_education_under_5_name),
            fld_anganwadi_school_name: replaceValue(role.fld_anganwadi_school_name),
            fld_mid_upper_arm_cirference: replaceValue(role.fld_mid_upper_arm_cirference),
            fld_head_circumference: replaceValue(role.fld_head_circumference),
            fld_chest_circumference: replaceValue(role.fld_chest_circumference),
            fld_immunisation_status_name: replaceValue(role.fld_immunisation_status_name),
            fld_due_date_nxt_vaccination: replaceValue(role.fld_due_date_nxt_vaccination),
            fld_weight_for_age_who_name: replaceValue(role.fld_weight_for_age_who_name),
            fld_height_for_age_who_name: replaceValue(role.fld_height_for_age_who_name),
            // fld_weight_for_height_who_name: replaceValue(role.fld_weight_for_height_who_name),
            fld_oral_check_up: replaceValue(role.fld_oral_check_up),
            fld_any_chronic_illness: replaceValue(role.fld_any_chronic_illness),
            fld_any_hospltion_past_1_year: replaceValue(role.fld_any_hospltion_past_1_year),
            fld_history_repe_infec_pst_1_year: replaceValue(role.fld_history_repe_infec_pst_1_year),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),

          })
        );
        setTab2Data(Tab2Slno);
       //tab3
        setTab3Data(responseData.trn_tbl_hh_behavioral_determinants_form);
        const Tab3Slno = result.responsemessage.trn_tbl_hh_behavioral_determinants_form.map(
          (role, index) => ({ ...role, slNo: index + 1,
            fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
            fld_diet_name: replaceValue(role.fld_diet_name),
            fld_stpl_dt_vagetable_intake_name: replaceValue(role.fld_stpl_dt_vagetable_intake_name),
            fld_veg_intake_frequency_name: replaceValue(role.fld_veg_intake_frequency_name),
            fld_stpl_dt_green_veg_intake_name: replaceValue(role.fld_stpl_dt_green_veg_intake_name),
            fld_green_veg_intake_frequency_name: replaceValue(role.fld_green_veg_intake_frequency_name),
            fld_stpl_dt_fruits_intake_name: replaceValue(role.fld_stpl_dt_fruits_intake_name),
            fld_fruits_intake_frequency_name: replaceValue(role.fld_fruits_intake_frequency_name),
            fld_junk_food_intake_name: replaceValue(role.fld_junk_food_intake_name),
            fld_junk_food_intake_frequency_name: replaceValue(role.fld_junk_food_intake_frequency_name),
            // fld_stpl_dt_suger_drnk_intake_name: replaceValue(role.fld_stpl_dt_suger_drnk_intake_name),
            fld_crls_grains_frm_item_name: replaceValue(role.fld_crls_grains_frm_item_name),
            fld_crls_grains_frm_item_frequency_name: replaceValue(role.fld_crls_grains_frm_item_frequency_name),
            fld_vitamin_a_rich_plant_food_name: replaceValue(role.fld_vitamin_a_rich_plant_food_name),
            fld_vitamin_a_rich_plant_food_frequiency_name: replaceValue(role.fld_vitamin_a_rich_plant_food_frequiency_name),
            fld_egg_name: replaceValue(role.fld_egg_name),
            fld_egg_frequency_name: replaceValue(role.fld_egg_frequency_name),
            fld_pulses_legums_name: replaceValue(role.fld_pulses_legums_name),
            fld_pulses_legums_frequency_name: replaceValue(role.fld_pulses_legums_frequency_name),
            fld_nuts_seeds_name: replaceValue(role.fld_nuts_seeds_name),
            fld_nuts_seeds_frequency_name: replaceValue(role.fld_nuts_seeds_frequency_name),
            fld_milk_product_name: replaceValue(role.fld_milk_product_name),
            fld_milk_product_fequency_name: replaceValue(role.fld_milk_product_fequency_name),
            fld_meat_product_name: replaceValue(role.fld_meat_product_name),
            fld_meat_product_frequency_name: replaceValue(role.fld_meat_product_frequency_name),
            fld_fish_other_sea_food_name: replaceValue(role.fld_fish_other_sea_food_name),
            fld_fish_other_sea_food_frequency_name: replaceValue(role.fld_fish_other_sea_food_frequency_name),
            fld_any_ph_activity_name: replaceValue(role.fld_any_ph_activity_name),
            fld_pa_frequency_name: replaceValue(role.fld_pa_frequency_name),
            fld_pa_type_name: replaceValue(role.fld_pa_type_name),
            fld_pa_other: replaceValue(role.fld_pa_other),
            fld_sub_smoke_sta_tobco_use_name: replaceValue(role.fld_sub_smoke_sta_tobco_use_name),
            fld_sub_smokeless_tobco_name: replaceValue(role.fld_sub_smokeless_tobco_name),
            fld_sub_consuptn_name: replaceValue(role.fld_sub_consuptn_name),
            fld_sub_am_fmly_use_illict_drug_name: replaceValue(role.fld_sub_am_fmly_use_illict_drug_name),
            fld_sub_specify_what: replaceValue(role.fld_sub_specify_what),
            fld_sleep_duration_avg_night_name: replaceValue(role.fld_sleep_duration_avg_night_name),
            fld_sleep_quality_rate_name: replaceValue(role.fld_sleep_quality_rate_name),
            fld_reg_hc_vis_hp_routine_chkp_name: replaceValue(role.fld_reg_hc_vis_hp_routine_chkp_name),
            fld_str_mngmt_eng_act_mng_stress_name: replaceValue(role.fld_str_mngmt_eng_act_mng_stress_name),
            fld_mhsu_have_evr_soght_help_mntl_helth_name: replaceValue(role.fld_mhsu_have_evr_soght_help_mntl_helth_name),
            fld_please_specify_name: replaceValue(role.fld_please_specify_name),
            fld_seat_belt_use_when_driving_car_name: replaceValue(role.fld_seat_belt_use_when_driving_car_name),
            fld_helmet_use_when_riding_motorcycle_name: replaceValue(role.fld_helmet_use_when_riding_motorcycle_name),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),

           })
        );
        setTab3Data(Tab3Slno);
        //tab4
        setTab4Data(responseData.trn_tbl_hh_socio_cultural_form);
        const Tab4Slno = result.responsemessage.trn_tbl_hh_socio_cultural_form.map(
          (role, index) => ({ ...role, slNo: index + 1 ,
            fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
            fld_beli_influ_sprit_relg_ph_name: replaceValue(role.fld_beli_influ_sprit_relg_ph_name),
            fld_hhd_mak_who_makes_dec_in_HH_name: replaceValue(role.fld_hhd_mak_who_makes_dec_in_HH_name),
            fld_other_family_member: replaceValue(role.fld_other_family_member),
            fld_gender_impt_access_hc_name: replaceValue(role.fld_gender_impt_access_hc_name),
            fld_cult_soco_pract_woman_helth_in_comity_name: replaceValue(role.fld_cult_soco_pract_woman_helth_in_comity_name),
            fld_cultural_social_practices: replaceValue(role.fld_cultural_social_practices),
            fld_expo_polltts_yur_home_nehod_name: replaceValue(role.fld_expo_polltts_yur_home_nehod_name),
            fld_other_expo_pollutants: replaceValue(role.fld_other_expo_pollutants),
            fld_waterborne_dieseas_name: replaceValue(role.fld_waterborne_dieseas_name),
            fld_other_expfld_waterborne_dieseas_nameo_pollutants: replaceValue(role.fld_other_expfld_waterborne_dieseas_nameo_pollutants),
            fld_occupation_hazard_name: replaceValue(role.fld_occupation_hazard_name),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),

          })
        );
        setTab4Data(Tab4Slno);
        //tab5
        setTab5Data(responseData.trn_tbl_economic_determinants_form);
        const Tab5Slno = result.responsemessage.trn_tbl_economic_determinants_form.map(
          (role, index) => ({ ...role, slNo: index + 1 ,
            fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
            fld_access_transport_name: replaceValue(role.fld_access_transport_name),
            fld_other_access_transpot: replaceValue(role.fld_other_access_transpot),
            fld_how_secure_feel_curt_job_name: replaceValue(role.fld_how_secure_feel_curt_job_name),
            fld_have_any_saving_name: replaceValue(role.fld_have_any_saving_name),
            fld_currently_in_debt_name: replaceValue(role.fld_currently_in_debt_name),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),

          })
        );
        setTab5Data(Tab5Slno);
        //tab6
        setTab6Data(responseData.trn_tbl_health_service_form);
        const Tab6Slno = result.responsemessage.trn_tbl_health_service_form.map(
          (role, index) => ({ ...role, slNo: index + 1 ,
            fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
            fld_when_last_helth_chkup: replaceValue(role.fld_when_last_helth_chkup),
            fld_hc_facility_available_name: replaceValue(role.fld_hc_facility_available_name),
            fld_pref_which_hc_facility_name: replaceValue(role.fld_pref_which_hc_facility_name),
            fld_near_hcf_frm_home_name: replaceValue(role.fld_near_hcf_frm_home_name),
            fld_how_log_wait_hcp_name: replaceValue(role.fld_how_log_wait_hcp_name),
            fld_rate_affordability_hsc_name: replaceValue(role.fld_rate_affordability_hsc_name),
            fld_can_afford_hc_needed_name: replaceValue(role.fld_can_afford_hc_needed_name),
            fld_do_hav_health_insurance_name: replaceValue(role.fld_do_hav_health_insurance_name),
            fld_how_much_spend_each_mnth_name: replaceValue(role.fld_how_much_spend_each_mnth_name),
            fld_barries_healthcare_name: replaceValue(role.fld_barries_healthcare_name),
            fld_other_sy_barries_healthcare: replaceValue(role.fld_other_sy_barries_healthcare),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),

          })
        );
        setTab6Data(Tab6Slno);
        //tab7
        setTab7Data(responseData.trn_tbl_location_geo_mapping);
        const Tab7Slno = result.responsemessage.trn_tbl_location_geo_mapping.map(
          (role, index) => ({ ...role, slNo: index + 1 ,
            fld_location_capture_date: replaceValue(role.fld_location_capture_date),
            fld_location_type_name: replaceValue(role.fld_location_type_name),
            fld_levels_of_education_name: replaceValue(role.fld_levels_of_education_name),
            fld_type_edu_inst_name: replaceValue(role.fld_type_edu_inst_name),
            fld_name_of_edu_institution: replaceValue(role.fld_name_of_edu_institution),
            fld_health_care_delivery_sys_name: replaceValue(role.fld_health_care_delivery_sys_name),
            fld_govt_hospital_type_name: replaceValue(role.fld_govt_hospital_type_name),
            fld_name_of_healthcare_center: replaceValue(role.fld_name_of_healthcare_center),
            fld_num_doctor: replaceValue(role.fld_num_doctor),
            fld_num_nurse: replaceValue(role.fld_num_nurse),
            fld_num_anm: replaceValue(role.fld_num_anm),
            fld_num_asha: replaceValue(role.fld_num_asha),
            fld_details_of_service_name: replaceValue(role.fld_details_of_service_name),
            fld_any_other_specify: replaceValue(role.fld_any_other_specify),
            fld_water_source_type_name: replaceValue(role.fld_water_source_type_name),
            fld_water_quality_parameter: replaceValue(role.fld_water_quality_parameter),
            fld_relegious_place_type_name: replaceValue(role.fld_relegious_place_type_name),
            fld_recreat_entertainmnt_type_name: replaceValue(role.fld_recreat_entertainmnt_type_name),
            fld_recreat_entertainmnt_name: replaceValue(role.fld_recreat_entertainmnt_name),
            fld_community_facility_type_name: replaceValue(role.fld_community_facility_type_name),
            fld_community_facility_name: replaceValue(role.fld_community_facility_name),
            fld_waste_managment_type_name: replaceValue(role.fld_waste_managment_type_name),
            fld_waste_managment_name: replaceValue(role.fld_waste_managment_name),
            fld_mar_fruit_veg_meat_pltry_area: replaceValue(role.fld_mar_fruit_veg_meat_pltry_area),
            fld_transportation_type_name: replaceValue(role.fld_transportation_type_name),
            fld_transportation_name: replaceValue(role.fld_transportation_name),
            fld_any_other_details_location: replaceValue(role.fld_any_other_details_location),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),

          })
        );
        setTab7Data(Tab7Slno);
      } else {
        console.log("api res", result.responsemessage);
        const errorMessage = result
          ? result.responsemessage || "No data found"
          : "Unexpected API response format";
        toast.current.show({
          severity: "error",
          summary: "Download Failed",
          detail: errorMessage,
        });
      }
    } catch (error) {
      console.error("Error fetching requested data:", error);
    } finally {
      setLoading(false);
    }
  };


  const rawDataAndDownload = async () => {
    try {
      setLoading(true);
      const requestData = {
        fld_asha_id: "admin",
        login_user_id: "admin",
        request_tables: {
          trn_tbl_hh_reg_form: "",
          trn_tbl_individual_details: "",
          trn_tbl_hh_behavioral_determinants_form: "",
          trn_tbl_hh_socio_cultural_form: "",
          trn_tbl_economic_determinants_form: "",
          trn_tbl_health_service_form: "",
          trn_tbl_location_geo_mapping: "",
        },
        synceddatetime: "2023-01-24 11:57:34",
        FormCode: "314",
        ApiKey: "kavin",
        AppTypeNo: "3",
        AppVersion: "1.0.0",
      };
  
      const result = await api.get_RawData_Report(requestData);
      const responseData = result.responsemessage;
  
      if (result && result.status === "1") {
        // Process data for each tab
        const tab1DataProcessed = responseData.trn_tbl_hh_reg_form.map((role, index) => ({
          ...role,
          slNo: index + 1,
          fld_asha_name: replaceValue(role.fld_asha_name),
          fld_state_name: replaceValue(role.fld_state_name),
          fld_district_name: replaceValue(role.fld_district_name),
          fld_taluk_name: replaceValue(role.fld_taluk_name),
          fld_village_name: replaceValue(role.fld_village_name),
          fld_informant_name: replaceValue(role.fld_informant_name),
          fld_family_head_name: replaceValue(role.fld_family_head_name),
          fld_phone_no_1: replaceValue(role.fld_phone_no_1),
          fld_phone_no_2: replaceValue(role.fld_phone_no_2),
          fld_address: replaceValue(role.fld_address),
          fld_residing_year_since: replaceValue(role.fld_residing_year_since),
          fld_previous_residence: replaceValue(role.fld_previous_residence),
          fld_native_place: replaceValue(role.fld_native_place),
          fld_reason_for_migration_name: replaceValue(role.fld_reason_for_migration_name),
          fld_other_reason: replaceValue(role.fld_other_reason),
          fld_landmark: replaceValue(role.fld_landmark),
          fld_type_of_family_name: replaceValue(role.fld_type_of_family_name),
          fld_other_social_sevice_card: replaceValue(role.fld_other_social_sevice_card),
          fld_card_holder_status_name: replaceValue(role.fld_card_holder_status_name),
          fld_total_family_member: replaceValue(role.fld_total_family_member),
          fld_total_family_income: replaceValue(role.fld_total_family_income),
          fld_any_property_own_Name: replaceValue(role.fld_any_property_own_Name),
          fld_property_details_name: replaceValue(role.fld_property_details_name),
          fld_socio_status_name: replaceValue(role.fld_socio_status_name),
          fld_religion_name: replaceValue(role.fld_religion_name),
          fld_other_religion: replaceValue(role.fld_other_religion),
          fld_caste_name: replaceValue(role.fld_caste_name),
          fld_rec_vtl_event_family_name: replaceValue(role.fld_rec_vtl_event_family_name),
          fld_others_event: replaceValue(role.fld_others_event),
          fld_ownership_of_house_name: replaceValue(role.fld_ownership_of_house_name),
          fld_others_ownership: replaceValue(role.fld_others_ownership),
          fld_family_cons_environmental_name: replaceValue(role.fld_family_cons_environmental_name),
          fld_detail_cons_environmental_name: replaceValue(role.fld_detail_cons_environmental_name),
          fld_open_drains_name: replaceValue(role.fld_open_drains_name),
          fld_type_of_house_name: replaceValue(role.fld_type_of_house_name),
          fld_over_crowding_name: replaceValue(role.fld_over_crowding_name),
          fld_ventilation_name: replaceValue(role.fld_ventilation_name),
          fld_lighting_name: replaceValue(role.fld_lighting_name),
          fld_access_electricty_name: replaceValue(role.fld_access_electricty_name),
          fld_num_electricty_house: replaceValue(role.fld_num_electricty_house),
          fld_acc_potbl_wat_name: replaceValue(role.fld_acc_potbl_wat_name),
          fld_kitchen_name: replaceValue(role.fld_kitchen_name),
          fld_cooking_fuel_used_name: replaceValue(role.fld_cooking_fuel_used_name),
          fld_cooking_fuel_other: replaceValue(role.fld_cooking_fuel_other),
          fld_smoke_outlet_name: replaceValue(role.fld_smoke_outlet_name),
          fld_source_water_supply_name: replaceValue(role.fld_source_water_supply_name),
          fld_other_water_supply: replaceValue(role.fld_other_water_supply),
          fld_water_supply_timing_name: replaceValue(role.fld_water_supply_timing_name),
          fld_storage_of_water_name: replaceValue(role.fld_storage_of_water_name),
          fld_other_water_storage: replaceValue(role.fld_other_water_storage),
          fld_water_used_drinking_name: replaceValue(role.fld_water_used_drinking_name),
          fld_other_drinking_water_used: replaceValue(role.fld_other_drinking_water_used),
          fld_toilet_facility_name: replaceValue(role.fld_toilet_facility_name),
          fld_water_supply_toilet_name: replaceValue(role.fld_water_supply_toilet_name),
          fld_handwashing_fac_toilet_use_name: replaceValue(role.fld_handwashing_fac_toilet_use_name),
          fld_pres_insect_flies_rodent_name: replaceValue(role.fld_pres_insect_flies_rodent_name),
          fld_pres_domestic_animal_name: replaceValue(role.fld_pres_domestic_animal_name),
          fld_domestic_animal_name: replaceValue(role.fld_domestic_animal_name),
          // fld_animalwaste_dispo_method: replaceValue(role.fld_animalwaste_dispo_method),
          fld_pres_pet_animal_name: replaceValue(role.fld_pres_pet_animal_name),
          fld_pet_animal: replaceValue(role.fld_pet_animal),
          fld_segrr_wastes_name: replaceValue(role.fld_segrr_wastes_name),
          fld_waste_bins_with_lid_name: replaceValue(role.fld_waste_bins_with_lid_name),
          fld_waste_disposal_name: replaceValue(role.fld_waste_disposal_name),
          fld_any_vect_breeding_site_name: replaceValue(role.fld_any_vect_breeding_site_name),
          fld_conf_disagreement_family_name: replaceValue(role.fld_conf_disagreement_family_name),
          fld_dom_voilence_women_name: replaceValue(role.fld_dom_voilence_women_name),
          fld_type_voilence_name: replaceValue(role.fld_type_voilence_name),
          // fld_how_fam_resolve_confl_name: replaceValue(role.fld_how_fam_resolve_confl_name),
          // fld_relation_with_relatives_name: replaceValue(role.fld_relation_with_relatives_name),
          // fld_interaction_with_neighbour_name: replaceValue(role.fld_interaction_with_neighbour_name),
          fld_person_with_disability_name: replaceValue(role.fld_person_with_disability_name),
          fld_natural_disability: replaceValue(role.fld_natural_disability),
          fld_soc_helth_problem_fac_famliy_name: replaceValue(role.fld_soc_helth_problem_fac_famliy_name),
          fld_sys_medi_prefered_name: replaceValue(role.fld_sys_medi_prefered_name),
          fld_sys_other_medi_prefered: replaceValue(role.fld_sys_other_medi_prefered),
          fld_near_hc_fac_avil_famliy: replaceValue(role.fld_near_hc_fac_avil_famliy),
          fld_distance_facility: replaceValue(role.fld_distance_facility),
          fld_transport_faci_mode_name: replaceValue(role.fld_transport_faci_mode_name),
          fld_near_gov_health_famliy_name: replaceValue(role.fld_near_gov_health_famliy_name),
          fld_other_facility: replaceValue(role.fld_other_facility),
          fld_num_family_mem_abha: replaceValue(role.fld_num_family_mem_abha),
          fld_access_digital_service_name: replaceValue(role.fld_access_digital_service_name),
          fld_health_insurance_coverage_name: replaceValue(role.fld_health_insurance_coverage_name),
          fld_type_insurance_name: replaceValue(role.fld_type_insurance_name),
          fld_access_financial_service_name: replaceValue(role.fld_access_financial_service_name),
          fld_geo_x: replaceValue(role.fld_geo_x),
          fld_geo_y: replaceValue(role.fld_geo_y),
        })); 
        const tab2DataProcessed = responseData.trn_tbl_individual_details.map((role, index) => ({
          ...role,
          slNo: index + 1,
          fld_date_of_visit: replaceValue(role.fld_date_of_visit),
            fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
            fld_aadhar_no: replaceValue(role.fld_aadhar_no),
            fld_dob: replaceValue(role.fld_dob),
            fld_age_years: replaceValue(role.fld_age_years),
            fld_age_month: replaceValue(role.fld_age_month),
            fld_gender_name: replaceValue(role.fld_gender_name),
            fld_relation_to_hh_name: replaceValue(role.fld_relation_to_hh_name),
            fld_relation_other_specify: replaceValue(role.fld_relation_other_specify),
            fld_marital_status_name: replaceValue(role.fld_marital_status_name),
            fld_atained_menarchy_name: replaceValue(role.fld_atained_menarchy_name),
            fld_regular_cycles_name: replaceValue(role.fld_regular_cycles_name),
            fld_menstruation_problem_specify: replaceValue(role.fld_menstruation_problem_specify),
            fld_preg_status_name: replaceValue(role.fld_preg_status_name),
            fld_thayi_card_name: replaceValue(role.fld_thayi_card_name),
            fld_already_having_child_name: replaceValue(role.fld_already_having_child_name),
            fld_how_many_children: replaceValue(role.fld_how_many_children),
            fld_any_abortions_name: replaceValue(role.fld_any_abortions_name),
            fld_how_many_abortions: replaceValue(role.fld_how_many_abortions),
            // fld_obstetric_score_name: replaceValue(role.fld_obstetric_score_name),
            // fld_score_name: replaceValue(role.fld_score_name),
            fld_when_was_first_child_born: replaceValue(role.fld_when_was_first_child_born),
            fld_problem_faced_previous_preg_name: replaceValue(role.fld_problem_faced_previous_preg_name),
            fld_problem_faced_other_spcify: replaceValue(role.fld_problem_faced_other_spcify),
            fld_problem_faced_name: replaceValue(role.fld_problem_faced_name),
            fld_attained_masopause_name: replaceValue(role.fld_attained_masopause_name),
            fld_any_health_issue_menopause: replaceValue(role.fld_any_health_issue_menopause),
            fld_diet_name: replaceValue(role.fld_diet_name),
            fld_education_name: replaceValue(role.fld_education_name),
            fld_occupation_name: replaceValue(role.fld_occupation_name),
            fld_other_occupation: replaceValue(role.fld_other_occupation),
            // fld_income: replaceValue(role.fld_income),
            // fld_hobies_any_name: replaceValue(role.fld_hobies_any_name),
            // fld_other_hobies: replaceValue(role.fld_other_hobies),
            fld_height_in_cm: replaceValue(role.fld_height_in_cm),
            fld_weight_in_kg: replaceValue(role.fld_weight_in_kg),
            fld_bmi: replaceValue(role.fld_bmi),
            fld_bmi_interpretation: replaceValue(role.fld_bmi_interpretation),
            fld_waist_in_inch: replaceValue(role.fld_waist_in_inch),
            fld_waist_interpretation: replaceValue(role.fld_waist_interpretation),
            fld_blood_pressure: replaceValue(role.fld_blood_pressure),
            fld_blood_group_name: replaceValue(role.fld_blood_group_name),
            fld_any_family_ho_chr_dis_name: replaceValue(role.fld_any_family_ho_chr_dis_name),
            fld_fam_ho_chronic_dise_name: replaceValue(role.fld_fam_ho_chronic_dise_name),
            fld_chronic_dise_others_specify: replaceValue(role.fld_chronic_dise_others_specify),
            fld_having_inherited_desease_name: replaceValue(role.fld_having_inherited_desease_name),
            fld_disease_specify: replaceValue(role.fld_disease_specify),
            fld_self_repo_chronic_cond_name: replaceValue(role.fld_self_repo_chronic_cond_name),
            fld_diabetes_since_when: replaceValue(role.fld_diabetes_since_when),
            fld_diabetes_medication_name: replaceValue(role.fld_diabetes_medication_name),
            fld_hypertension_since_when: replaceValue(role.fld_hypertension_since_when),
            fld_hytension_medtion_name: replaceValue(role.fld_hytension_medtion_name),
            fld_heart_dis_since_when: replaceValue(role.fld_heart_dis_since_when),
            fld_heart_dis_medtion_name: replaceValue(role.fld_heart_dis_medtion_name),
            fld_asthama_since_when: replaceValue(role.fld_asthama_since_when),
            fld_asthama_medication_name: replaceValue(role.fld_asthama_medication_name),
            fld_kidn_dis_since_when: replaceValue(role.fld_kidn_dis_since_when),
            fld_kidn_dis_medition_name: replaceValue(role.fld_kidn_dis_medition_name),
            fld_arthritis_since_when: replaceValue(role.fld_arthritis_since_when),
            fld_arthritis_medication_name: replaceValue(role.fld_arthritis_medication_name),
            fld_cvd_stroke_since_when: replaceValue(role.fld_cvd_stroke_since_when),
            fld_cvd_stroke_medication_name: replaceValue(role.fld_cvd_stroke_medication_name),
            fld_cancer_type: replaceValue(role.fld_cancer_type),
            fld_cancer_since_when: replaceValue(role.fld_cancer_since_when),
            fld_cancer_und_trtment_name: replaceValue(role.fld_cancer_und_trtment_name),
            fld_cancer_any_surges_name: replaceValue(role.fld_cancer_any_surges_name),
            fld_self_repot_other_specify: replaceValue(role.fld_self_repot_other_specify),
            fld_other_specify_since_when: replaceValue(role.fld_other_specify_since_when),
            fld_other_specify_medition_name: replaceValue(role.fld_other_specify_medition_name),
            fld_self_rep_inf_dis_pst_1_year_name: replaceValue(role.fld_self_rep_inf_dis_pst_1_year_name),
            fld_self_rep_inf_dis_others_sy: replaceValue(role.fld_self_rep_inf_dis_others_sy),
            fld_self_reported_allergies_name: replaceValue(role.fld_self_reported_allergies_name),
            fld_allergies_others_specify: replaceValue(role.fld_allergies_others_specify),
            fld_self_repo_reprod_health_issue: replaceValue(role.fld_self_repo_reprod_health_issue),
            fld_diet_under_5_name: replaceValue(role.fld_diet_under_5_name),
            fld_feeding_status_name: replaceValue(role.fld_feeding_status_name),
            fld_education_under_5_name: replaceValue(role.fld_education_under_5_name),
            fld_anganwadi_school_name: replaceValue(role.fld_anganwadi_school_name),
            fld_mid_upper_arm_cirference: replaceValue(role.fld_mid_upper_arm_cirference),
            fld_head_circumference: replaceValue(role.fld_head_circumference),
            fld_chest_circumference: replaceValue(role.fld_chest_circumference),
            fld_immunisation_status_name: replaceValue(role.fld_immunisation_status_name),
            fld_due_date_nxt_vaccination: replaceValue(role.fld_due_date_nxt_vaccination),
            fld_weight_for_age_who_name: replaceValue(role.fld_weight_for_age_who_name),
            fld_height_for_age_who_name: replaceValue(role.fld_height_for_age_who_name),
            // fld_weight_for_height_who_name: replaceValue(role.fld_weight_for_height_who_name),
            fld_oral_check_up: replaceValue(role.fld_oral_check_up),
            fld_any_chronic_illness: replaceValue(role.fld_any_chronic_illness),
            fld_any_hospltion_past_1_year: replaceValue(role.fld_any_hospltion_past_1_year),
            fld_history_repe_infec_pst_1_year: replaceValue(role.fld_history_repe_infec_pst_1_year),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),
        }));
  
        const tab3DataProcessed = responseData.trn_tbl_hh_behavioral_determinants_form.map((role, index) => ({
          ...role,
          slNo: index + 1,
          fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
            fld_diet_name: replaceValue(role.fld_diet_name),
            fld_stpl_dt_vagetable_intake_name: replaceValue(role.fld_stpl_dt_vagetable_intake_name),
            fld_veg_intake_frequency_name: replaceValue(role.fld_veg_intake_frequency_name),
            fld_stpl_dt_green_veg_intake_name: replaceValue(role.fld_stpl_dt_green_veg_intake_name),
            fld_green_veg_intake_frequency_name: replaceValue(role.fld_green_veg_intake_frequency_name),
            fld_stpl_dt_fruits_intake_name: replaceValue(role.fld_stpl_dt_fruits_intake_name),
            fld_fruits_intake_frequency_name: replaceValue(role.fld_fruits_intake_frequency_name),
            fld_junk_food_intake_name: replaceValue(role.fld_junk_food_intake_name),
            fld_junk_food_intake_frequency_name: replaceValue(role.fld_junk_food_intake_frequency_name),
            // fld_stpl_dt_suger_drnk_intake_name: replaceValue(role.fld_stpl_dt_suger_drnk_intake_name),
            fld_crls_grains_frm_item_name: replaceValue(role.fld_crls_grains_frm_item_name),
            fld_crls_grains_frm_item_frequency_name: replaceValue(role.fld_crls_grains_frm_item_frequency_name),
            fld_vitamin_a_rich_plant_food_name: replaceValue(role.fld_vitamin_a_rich_plant_food_name),
            fld_vitamin_a_rich_plant_food_frequiency_name: replaceValue(role.fld_vitamin_a_rich_plant_food_frequiency_name),
            fld_egg_name: replaceValue(role.fld_egg_name),
            fld_egg_frequency_name: replaceValue(role.fld_egg_frequency_name),
            fld_pulses_legums_name: replaceValue(role.fld_pulses_legums_name),
            fld_pulses_legums_frequency_name: replaceValue(role.fld_pulses_legums_frequency_name),
            fld_nuts_seeds_name: replaceValue(role.fld_nuts_seeds_name),
            fld_nuts_seeds_frequency_name: replaceValue(role.fld_nuts_seeds_frequency_name),
            fld_milk_product_name: replaceValue(role.fld_milk_product_name),
            fld_milk_product_fequency_name: replaceValue(role.fld_milk_product_fequency_name),
            fld_meat_product_name: replaceValue(role.fld_meat_product_name),
            fld_meat_product_frequency_name: replaceValue(role.fld_meat_product_frequency_name),
            fld_fish_other_sea_food_name: replaceValue(role.fld_fish_other_sea_food_name),
            fld_fish_other_sea_food_frequency_name: replaceValue(role.fld_fish_other_sea_food_frequency_name),
            fld_any_ph_activity_name: replaceValue(role.fld_any_ph_activity_name),
            fld_pa_frequency_name: replaceValue(role.fld_pa_frequency_name),
            fld_pa_type_name: replaceValue(role.fld_pa_type_name),
            fld_pa_other: replaceValue(role.fld_pa_other),
            fld_sub_smoke_sta_tobco_use_name: replaceValue(role.fld_sub_smoke_sta_tobco_use_name),
            fld_sub_smokeless_tobco_name: replaceValue(role.fld_sub_smokeless_tobco_name),
            fld_sub_consuptn_name: replaceValue(role.fld_sub_consuptn_name),
            fld_sub_am_fmly_use_illict_drug_name: replaceValue(role.fld_sub_am_fmly_use_illict_drug_name),
            fld_sub_specify_what: replaceValue(role.fld_sub_specify_what),
            fld_sleep_duration_avg_night_name: replaceValue(role.fld_sleep_duration_avg_night_name),
            fld_sleep_quality_rate_name: replaceValue(role.fld_sleep_quality_rate_name),
            fld_reg_hc_vis_hp_routine_chkp_name: replaceValue(role.fld_reg_hc_vis_hp_routine_chkp_name),
            fld_str_mngmt_eng_act_mng_stress_name: replaceValue(role.fld_str_mngmt_eng_act_mng_stress_name),
            fld_mhsu_have_evr_soght_help_mntl_helth_name: replaceValue(role.fld_mhsu_have_evr_soght_help_mntl_helth_name),
            fld_please_specify_name: replaceValue(role.fld_please_specify_name),
            fld_seat_belt_use_when_driving_car_name: replaceValue(role.fld_seat_belt_use_when_driving_car_name),
            fld_helmet_use_when_riding_motorcycle_name: replaceValue(role.fld_helmet_use_when_riding_motorcycle_name),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),
        }));
        const tab4DataProcessed = responseData.trn_tbl_hh_socio_cultural_form.map((role, index) => ({
          ...role,
          slNo: index + 1,
          fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
          fld_beli_influ_sprit_relg_ph_name: replaceValue(role.fld_beli_influ_sprit_relg_ph_name),
          fld_hhd_mak_who_makes_dec_in_HH_name: replaceValue(role.fld_hhd_mak_who_makes_dec_in_HH_name),
          fld_other_family_member: replaceValue(role.fld_other_family_member),
          fld_gender_impt_access_hc_name: replaceValue(role.fld_gender_impt_access_hc_name),
          fld_cult_soco_pract_woman_helth_in_comity_name: replaceValue(role.fld_cult_soco_pract_woman_helth_in_comity_name),
          fld_cultural_social_practices: replaceValue(role.fld_cultural_social_practices),
          fld_expo_polltts_yur_home_nehod_name: replaceValue(role.fld_expo_polltts_yur_home_nehod_name),
          fld_other_expo_pollutants: replaceValue(role.fld_other_expo_pollutants),
          fld_waterborne_dieseas_name: replaceValue(role.fld_waterborne_dieseas_name),
          fld_other_expfld_waterborne_dieseas_nameo_pollutants: replaceValue(role.fld_other_expfld_waterborne_dieseas_nameo_pollutants),
          fld_occupation_hazard_name: replaceValue(role.fld_occupation_hazard_name),
          fld_geo_x: replaceValue(role.fld_geo_x),
          fld_geo_y: replaceValue(role.fld_geo_y),

        }));
        const tab5DataProcessed = responseData.trn_tbl_economic_determinants_form.map((role, index) => ({
          ...role,
          slNo: index + 1,
          fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
          fld_access_transport_name: replaceValue(role.fld_access_transport_name),
          fld_other_access_transpot: replaceValue(role.fld_other_access_transpot),
          fld_how_secure_feel_curt_job_name: replaceValue(role.fld_how_secure_feel_curt_job_name),
          fld_have_any_saving_name: replaceValue(role.fld_have_any_saving_name),
          fld_currently_in_debt_name: replaceValue(role.fld_currently_in_debt_name),
          fld_geo_x: replaceValue(role.fld_geo_x),
          fld_geo_y: replaceValue(role.fld_geo_y),
         
        }));
        const tab6DataProcessed = responseData.trn_tbl_health_service_form.map((role, index) => ({
          ...role,
          slNo: index + 1,
          fld_individual_or_child_name: replaceValue(role.fld_individual_or_child_name),
          fld_when_last_helth_chkup: replaceValue(role.fld_when_last_helth_chkup),
          fld_hc_facility_available_name: replaceValue(role.fld_hc_facility_available_name),
          fld_pref_which_hc_facility_name: replaceValue(role.fld_pref_which_hc_facility_name),
          fld_near_hcf_frm_home_name: replaceValue(role.fld_near_hcf_frm_home_name),
          fld_how_log_wait_hcp_name: replaceValue(role.fld_how_log_wait_hcp_name),
          fld_rate_affordability_hsc_name: replaceValue(role.fld_rate_affordability_hsc_name),
          fld_can_afford_hc_needed_name: replaceValue(role.fld_can_afford_hc_needed_name),
          fld_do_hav_health_insurance_name: replaceValue(role.fld_do_hav_health_insurance_name),
          fld_how_much_spend_each_mnth_name: replaceValue(role.fld_how_much_spend_each_mnth_name),
          fld_barries_healthcare_name: replaceValue(role.fld_barries_healthcare_name),
          fld_other_sy_barries_healthcare: replaceValue(role.fld_other_sy_barries_healthcare),
          fld_geo_x: replaceValue(role.fld_geo_x),
          fld_geo_y: replaceValue(role.fld_geo_y),
        }));
        const tab7DataProcessed = responseData.trn_tbl_location_geo_mapping.map((role, index) => ({
          ...role,
          slNo: index + 1,
          fld_location_capture_date: replaceValue(role.fld_location_capture_date),
            fld_location_type_name: replaceValue(role.fld_location_type_name),
            fld_levels_of_education_name: replaceValue(role.fld_levels_of_education_name),
            fld_type_edu_inst_name: replaceValue(role.fld_type_edu_inst_name),
            fld_name_of_edu_institution: replaceValue(role.fld_name_of_edu_institution),
            fld_health_care_delivery_sys_name: replaceValue(role.fld_health_care_delivery_sys_name),
            fld_govt_hospital_type_name: replaceValue(role.fld_govt_hospital_type_name),
            fld_name_of_healthcare_center: replaceValue(role.fld_name_of_healthcare_center),
            fld_num_doctor: replaceValue(role.fld_num_doctor),
            fld_num_nurse: replaceValue(role.fld_num_nurse),
            fld_num_anm: replaceValue(role.fld_num_anm),
            fld_num_asha: replaceValue(role.fld_num_asha),
            fld_details_of_service_name: replaceValue(role.fld_details_of_service_name),
            fld_any_other_specify: replaceValue(role.fld_any_other_specify),
            fld_water_source_type_name: replaceValue(role.fld_water_source_type_name),
            fld_water_quality_parameter: replaceValue(role.fld_water_quality_parameter),
            fld_relegious_place_type_name: replaceValue(role.fld_relegious_place_type_name),
            fld_recreat_entertainmnt_type_name: replaceValue(role.fld_recreat_entertainmnt_type_name),
            fld_recreat_entertainmnt_name: replaceValue(role.fld_recreat_entertainmnt_name),
            fld_community_facility_type_name: replaceValue(role.fld_community_facility_type_name),
            fld_community_facility_name: replaceValue(role.fld_community_facility_name),
            fld_waste_managment_type_name: replaceValue(role.fld_waste_managment_type_name),
            fld_waste_managment_name: replaceValue(role.fld_waste_managment_name),
            fld_mar_fruit_veg_meat_pltry_area: replaceValue(role.fld_mar_fruit_veg_meat_pltry_area),
            fld_transportation_type_name: replaceValue(role.fld_transportation_type_name),
            fld_transportation_name: replaceValue(role.fld_transportation_name),
            fld_any_other_details_location: replaceValue(role.fld_any_other_details_location),
            fld_geo_x: replaceValue(role.fld_geo_x),
            fld_geo_y: replaceValue(role.fld_geo_y),
        }));
  
        // After processing data, trigger the download
        const allData = [
          { tabName: 'HouseHold Registration', data: tab1DataProcessed, headers: excelHeaders.tab1Data },
          { tabName: 'Individual details & child', data: tab2DataProcessed, headers: excelHeaders.tab2Data },
          { tabName: 'Behavioural determinants', data: tab3DataProcessed, headers: excelHeaders.tab3Data },
          { tabName: 'Socio cultural determinants', data: tab4DataProcessed, headers: excelHeaders.tab4Data },
          { tabName: 'Economic determinant', data: tab5DataProcessed, headers: excelHeaders.tab5Data },
          { tabName: 'Health services', data: tab6DataProcessed, headers: excelHeaders.tab6Data },
          { tabName: 'Location Geo Mapping', data: tab7DataProcessed, headers: excelHeaders.tab7Data },
 
        ];
  
        const wb = XLSX.utils.book_new();
        allData.forEach(({ tabName, data, headers }) => {
          const headerTexts = headers.map(header => typeof header === 'object' ? header.text : header);
          const keys = headers.map(header => typeof header === 'object' ? header.key : header);
  
          const wsData = data.map(row => keys.map(key => row[key]));
          wsData.unshift(headerTexts);
  
          const ws = XLSX.utils.aoa_to_sheet(wsData);
          XLSX.utils.book_append_sheet(wb, ws, tabName);
        });
  
        // const downloadDate = new Date().toLocaleDateString();
        XLSX.writeFile(wb, `Swasthya_Raw_data_${downloadDate}.xlsx`);
  
        toast.current.show({
          severity: "success",
          summary: "Successfully downloaded",
          detail: "Raw data downloaded",
          life: 2000,
          closable: true,
          sticky: false,
        });
      } else {
        const errorMessage = result ? result.responsemessage || "No data found" : "Unexpected API response format";
        toast.current.show({
          severity: "error",
          summary: "Download Failed",
          detail: errorMessage,
        });
      }
    } catch (error) {
      console.error("Error fetching requested data:", error);
      toast.current.show({
        severity: "error",
        summary: "Download Failed",
        detail: "Error fetching requested data.",
      });
    } finally {
      setLoading(false);
    }
  };


  // useEffect(() => {
  //   rawData();
  // }, []);

  return (
    <div className="m-5">
        {loading && (
        <div className="pro-spin">
          <ProgressSpinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      )}
      <h1 className="heading">Reports</h1>
      <Toast ref={toast} />
      <Divider/>
      <div className="mt-5 ml-2">
        <Button
          icon="pi pi-download"
          label="Download CSV"
          className="csvDownloadbutton"
          onClick={rawDataAndDownload}
        />
      </div>
    </div>
  );
};

export default Reports;
